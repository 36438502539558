import { FC } from 'react';

import { Match, ModuleScheduleSection } from '@common/clients/api';
import { BettingDisclaimer } from '@web/atoms/BettingDisclaimer';
import { BettingIntegrationTracker } from '@web/atoms/BettingIntegrationTracker';
import { Route } from '@web/routing';

import { OddGroup } from './atoms/OddGroup';

import styles from './OddsBlock.module.scss';

export interface Props {
    match: Match;
    isRectangleBlock?: boolean;
    hasDarkBackground?: boolean;
    hideDisclaimer?: boolean;
    hasLimitedSpace?: boolean;
    isCompact?: boolean;
    hide18PlusLogo?: boolean;
    isLarger?: boolean;
    isMatchOfTheDay?: boolean;
    /** @deprecated Remove trackerName once the new betting reports are fully working */
    trackerName: string | false;
    section?: ModuleScheduleSection;
    route?: Route;
}

export const OddsBlock: FC<Props> = (props) => {
    const match = props.match;
    if (!match.odds) return <></>;

    const classes = ['odds-element'];
    const dataItems: { [key: string]: string } = {};

    classes.push(styles.OddsBlock);
    if (props.hasLimitedSpace) classes.push(styles['has-limited-space']);
    if (props.isRectangleBlock) classes.push(styles['is-block']);
    if (props.hasDarkBackground) classes.push(styles['has-dark-background']);
    if (props.isCompact) classes.push(styles['is-compact']);
    if (props.isLarger) classes.push(styles['is-larger']);
    if (props.isMatchOfTheDay) classes.push(styles['is-match-of-the-day']);
    if (props.trackerName) {
        classes.push('ndm-betting-integration-tracker');
        dataItems['data-tracker-name'] = props.trackerName;
    }

    return (
        <div className={classes.join(' ')} {...dataItems}>
            {match.odds?.map((threeWayOdds, i) => {
                // Skip oddGroup if it's not the primary bookmaker or only odds group
                if (props.isRectangleBlock && !(threeWayOdds.isPrimaryBookmaker || match?.odds?.length === 1))
                    return <></>;
                return (
                    <>
                        <OddGroup key={i} {...props} odds={threeWayOdds} />
                        {threeWayOdds.bookmaker && props.section ? (
                            <BettingIntegrationTracker
                                bookmakerID={threeWayOdds.bookmaker?.id}
                                module={props.section}
                                route={props.route}
                            />
                        ) : null}
                    </>
                );
            })}

            <div className={styles['disclaimer-wrapper']}>
                {!props.hideDisclaimer ? <BettingDisclaimer showEighteenPlusLogo /> : null}
            </div>
        </div>
    );
};
