import { FC, ReactNode } from 'react';

import { Match, Team } from '@common/clients/api';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { ResponsiveToggle } from '@web/atoms/ResponsiveToggle';
import { TagImage } from '@web/atoms/TagImage';

import styles from './TeamElement.module.scss';

export interface Props {
    element?: 'li' | 'span' | 'div';
    match?: Match;
    allowMatchFallBackUrl?: boolean;
    team: Team;
    logoAlignment?: 'left' | 'right';
    useShortName?: boolean;
    isLarge?: boolean;
    isVertical?: boolean;
}

export const TeamElement: FC<Props> = ({
    element,
    match,
    allowMatchFallBackUrl,
    team,
    logoAlignment,
    useShortName,
    isLarge,
    isVertical,
}) => {
    const classes = [styles.TeamElement, 'team-element'];

    if (logoAlignment === 'left') classes.push(styles['is-left']);
    if (isLarge) classes.push(styles['is-large']);
    if (isVertical) classes.push(styles['is-vertical']);

    if (match && match.homeScore !== undefined && match.awayScore !== undefined) {
        if (
            (match.home?.id === team.id && match.homeScore > match.awayScore) ||
            (match.away?.id === team.id && match.homeScore < match.awayScore)
        ) {
            // Winner team
            classes.push(styles.won);
        } else if (
            (match.home?.id === team.id && match.homeScore < match.awayScore) ||
            (match.away?.id === team.id && match.homeScore > match.awayScore)
        ) {
            // Loser team
            classes.push(styles.lost);
        }
    }

    let teamName: ReactNode = '';
    let shortName: string;
    if (team.shortName) {
        shortName = team.shortName;
    } else if (team.tag?.shortTitle) {
        shortName = team.tag?.shortTitle;
    } else {
        shortName = team.name
            // Keep all alphanumeric characters from any language
            .replace(/[^\p{L}\d]/gu, '')
            .toUpperCase()
            .substring(0, 3);
    }

    if (useShortName) {
        teamName = shortName;
    } else if (useShortName === false) {
        teamName = team?.name;
    } else {
        teamName = <ResponsiveToggle desktop={team.name} mobile={shortName} />;
    }

    const ClubView = () => (
        <span className={styles.club}>
            <TagImage className={styles.logo} tag={team.tag} />
            <p className={styles['team-name']}>{teamName}</p>
        </span>
    );

    const Element = element ? element : 'span';
    const tagSlug = team?.tag?.tagSlug;
    const matchFallback = allowMatchFallBackUrl;
    return (
        <Element className={classes.join(' ')}>
            {!tagSlug && (!matchFallback || !match) ? (
                <ClubView />
            ) : (
                <InternalLink
                    className={team.tag ? styles.hasTag : ''}
                    route={tagSlug ? Route.Club : Route.Match}
                    query={tagSlug ? { tag: tagSlug } : { matchID: match?.id }}
                >
                    <ClubView />
                </InternalLink>
            )}
        </Element>
    );
};
